import { getIdUsuario } from '../../services/auth';

import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';

import RemoveSharpIcon from '@material-ui/icons/RemoveSharp';

// BUSCA DE NOVOS ICONES 
//https://v4.mui.com/pt/components/material-icons/#material-icons


export const mainListItems_o = [
    { name: "dashboard", label: "Dashboard", Icon: DashboardIcon, link: '/opengym/dashboard', nodeExpand: '' },
    {
        name: "cadastros",
        label: "Cadastros",
        Icon: AssignmentIcon,
        items: [
            { name: "usuarios", label: "Usuários", Icon: RemoveSharpIcon, link: '/opengym/usuarios', nodeExpand: 'cadastros' },
            { name: "academias", label: "Academias", Icon: RemoveSharpIcon, link: '/opengym/academia', nodeExpand: 'cadastros' },
            { name: "localidades", label: "Localidades", Icon: RemoveSharpIcon, link: '/opengym/location', nodeExpand: 'cadastros' },
            { name: "vouchers", label: "Vouchers", Icon: RemoveSharpIcon, link: '/opengym/voucher', nodeExpand: 'cadastros' },
            { name: "paymentProfiles", label: "Perfis de Pgto", Icon: RemoveSharpIcon, link: '/opengym/paymentProfile', nodeExpand: 'cadastros' }
        ]
    },
    {
        name: "leads",
        label: "Leads",
        Icon: EmojiPeopleIcon,
        items: [
            { name: "leadsNewsletter", label: "Newsletter", Icon: RemoveSharpIcon, link: '/opengym/newsletter', nodeExpand: 'leads' },
            { name: "leadsApp", label: "App", Icon: RemoveSharpIcon, link: '/opengym/leadsApp', nodeExpand: 'leads' },
            { name: "leadsAcademias", label: "Academias", Icon: RemoveSharpIcon, link: '/opengym/leadsGym', nodeExpand: 'leads' },
        ]
    },
    {
        name: "mkt",
        label: "Email Mkt",
        Icon: EmailIcon,
        items: [
            { name: "mktEnvio", label: "Envio", Icon: RemoveSharpIcon, link: '/opengym/mktEnvio', nodeExpand: 'mkt' },
            { name: "mktTemplate", label: "Template", Icon: RemoveSharpIcon, link: '/opengym/mktTemplate', nodeExpand: 'mkt' },
            { name: "mktSegmentacao", label: "Segmentação", Icon: RemoveSharpIcon, link: '/opengym/mktSegmentacao', nodeExpand: 'mkt' },
        ]
    },
    {
        name: "finance",
        label: "Financeiro",
        Icon: AccountBalanceOutlinedIcon,
        items: [
            { name: "invoice", label: "Faturamento", Icon: RemoveSharpIcon, link: '/opengym/invoice', nodeExpand: 'finance' },
        ]
    },    
    'divider',
    { name: "account", label: "Perfil do Usuário", Icon: AccountCircle, link: '/account1/' + getIdUsuario(), nodeExpand: '' },
    { name: "config", label: "Configurações", Icon: SettingsIcon, link: '/opengym/config' },
];