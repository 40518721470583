import React, { useContext, Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { makeStyles, Typography, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Box, Container, Button, DialogTitle, DialogActions, } from '@material-ui/core';

import ExitToApp from '@material-ui/icons/ExitToApp';

import { getNomeUsuario } from '../services/auth';
//import logoSistemaNormal from '../assets/img/logoPretoLaranja.svg';
import logoSistemaNormal from '../assets/img/logoPreto.svg';
import logoSistemaDark from '../assets/img/logoLaranja.svg';
import { CustomThemeContext } from '../themes/CustomThemeProvider';

import {useDispatch} from 'react-redux';
import * as Actions from '../store/actions';
import ShowDialog from '../components/FuseDialog';
import ShowMessage from '../components/FuseMessage';

import { logout } from "../services/auth";

import Footer from './footer-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 16,
          },
      },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    title: {
      flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
      },
  }));

const MenuAdmin = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const { currentTheme, currentTitle } = useContext(CustomThemeContext);
    const isDark = Boolean(currentTheme === 'dark');

  
    async function Sair(){
          // ERRO CORS
//        const response = await api.get("/api/usuarios/destroytoken",{headers:{token: getToken()}});
//        const response = await api.get("/api/usuarios/destroytoken");
//        if(response.status===200){
            dispatch(Actions.closeDialog());
            logout();
            //window.location.href = `/login/${sourceArea}`;

            window.location.href = '/login';
            return;

//        }else{
//          alert("Erro ao fazer o logout!");
//        }
    }

  return(
  <Fragment>
      <div className={classes.root}>

          <AppBar position="absolute" >
              <Toolbar className={classes.toolbar}>

                <div className={classes.toolbarIcon}>
                  <img style={{height:32, paddingRight: 20}} src={isDark? logoSistemaDark : logoSistemaNormal} alt="Logo sistema" />
                </div>

                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {currentTitle}
                </Typography>

                {getNomeUsuario()}

                <IconButton color="default" aria-label="theme" component="span"
                  onClick={
                    ()=> dispatch(Actions.openDialog({
                    children: (
                        <React.Fragment>
                            <DialogTitle id="alert-dialog-title">Deseja realmente sair do sistema?</DialogTitle>
                            <DialogActions>
                                <Button onClick={()=> dispatch(Actions.closeDialog())} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={Sair} color="primary" autoFocus>
                                    Sim
                                </Button>
                            </DialogActions>
                        </React.Fragment>
                    )
                    }))
                    }>
                  <ExitToApp />
                </IconButton>

              </Toolbar>
          </AppBar>

          <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth={false} className={classes.container}>
                <Outlet />
              </Container>
              <Box pt={1} pb={3}>
                  <Footer />
              </Box>
          </main>

      </div>
      <ShowDialog/>
      <ShowMessage/>
  </Fragment>
  );
    
};
  
export default MenuAdmin;
