import { lazy } from 'react';

// project imports
import MainLayout from '../components/menu-admin';
import Loadable from '../components/Loadable';
import { ProtectedRoute } from "./ProtectedRoute";
import { mainListItems_g } from '../components/gym/list-menu-admin';

//import { mainListItems_o } from './opengym/list-menu-admin';
//import { mainListItems_g } from './gym/list-menu-admin';
//import { mainListItems_u } from './user/list-menu-admin';

const DashboardGym = Loadable(lazy(() => import('../pages/admin/gym/dashboard')));
const UsuariosGym = Loadable(lazy(() => import('../pages/admin/gym/usuarios')));
const UsuarioEditarGym = Loadable(lazy(() => import('../pages/admin/gym/usuarios/usuarios.editar')));
const UsuarioCadastrarGym = Loadable(lazy(() => import('../pages/admin/gym/usuarios/usuarios.cadastrar')));

const AcademiaGym = Loadable(lazy(() => import('../pages/admin/gym/academia')));
const AcademiaEditarGym = Loadable(lazy(() => import('../pages/admin/gym/academia/academia.editar')));

const Checkins = Loadable(lazy(() => import('../pages/admin/gym/checkins')));
const CheckinsUser = Loadable(lazy(() => import('../pages/admin/gym/checkins/checkins.user')));

const PrecoDinamico = Loadable(lazy(() => import('../pages/admin/gym/precodinamico')));

const Financeiro = Loadable(lazy(() => import('../pages/admin/gym/financeiro')));
const FinanceiroCheckin = Loadable(lazy(() => import('../pages/admin/gym/financeiro/financeiro.checkins')));

const RatingsGym = Loadable(lazy(() => import('../pages/admin/gym/ratings')));

const Account = Loadable(lazy(() => import('../pages/admin/shared/account/index')));


// ==============================|| MAIN ROUTING ||============================== //
// Validação do Token
// source = 1 - Area OpenGym
// source = 2 - Area Academia Adm
// source = 3 - Area Aluno

const GymRoute = {
    path: '/',
    element: <MainLayout itens={mainListItems_g}/>,
    children: [
        {
            path: '/gym/dashboard',
            element: <ProtectedRoute source='2'><DashboardGym /></ProtectedRoute>
        },
        {
            path: '/gym/checkins/:idUsuario',
            element: <ProtectedRoute source='2'><Checkins /></ProtectedRoute>
        },
        {
            path: '/gym/checkins/user/:idUsuario/:idGymUser',
            element: <ProtectedRoute source='2'><CheckinsUser /></ProtectedRoute>
        },
        {
            path: '/gym/precodinamico/:idUsuario',
            element: <ProtectedRoute source='2'><PrecoDinamico /></ProtectedRoute>
        },
        {
            path: '/gym/financeiro/:idUsuario',
            element: <ProtectedRoute source='2'><Financeiro /></ProtectedRoute>
        },
        {
            path: '/gym/financeiro/checkins/:idUsuario/:idInvoice',
            element: <ProtectedRoute source='2'><FinanceiroCheckin /></ProtectedRoute>
        },
        {
            path: '/gym/academia/:idUsuario',
            element: <ProtectedRoute source='2'><AcademiaGym /></ProtectedRoute>
        },
        {
            path: '/gym/academia/editar/:idAcademia',
            element: <ProtectedRoute source='2'><AcademiaEditarGym /></ProtectedRoute>
        },
        {
            path: '/gym/usuarios/:idUsuario',
            element: <ProtectedRoute source='2'><UsuariosGym /></ProtectedRoute>
        },
        {
            path: '/gym/usuarios/cadastrar/:idUsuario',
            element: <ProtectedRoute source='2'><UsuarioCadastrarGym /></ProtectedRoute>
        },
        {
            path: '/gym/usuarios/editar/:idUsuario/:idGymUser',
            element: <ProtectedRoute source='2'><UsuarioEditarGym /></ProtectedRoute>
        },
        {
            path: '/gym/academia/ratings/:idAcademia',
            element: <ProtectedRoute source='2'><RatingsGym /></ProtectedRoute>
        },
        {
            path: '/account2/:idUsuario',
            element: <ProtectedRoute source='2'><Account /></ProtectedRoute>
        },
    ]
};

export default GymRoute;
