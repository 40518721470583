import Cookies from 'js-cookie';
import api from './api';

export const TOKEN_KEY = '@opengym/user1';
export const ID_USUARIO = '@opengym/user4';
export const NOME_USUARIO = '@opengym/nome-usuario';
export const USER_TYPE = '@opengym/user2';
export const USER_SOURCE = '@opengym/user3';

// rememberPassword DEFAULT .F.
export const login = (token, rememberPassword = false) => {
    if(rememberPassword) {
        Cookies.set(TOKEN_KEY, token, { expires:365 }); // 360 DIAS PARA EXPIRAR
    } else {
        Cookies.set(TOKEN_KEY, token);  // EXPIRA AO FECHAR O NAVEGADOR
    }
}
export const getToken = () => Cookies.get(TOKEN_KEY);

export const logout = () => {
    //localStorage.clear(); // Alterado para não excluir Tema Dark/Normal
    localStorage.removeItem( ID_USUARIO );
    localStorage.removeItem( NOME_USUARIO );
    localStorage.removeItem( USER_TYPE );
    localStorage.removeItem( USER_SOURCE );
    Cookies.remove( TOKEN_KEY );
}

export const setIdUsuario = id => localStorage.setItem( ID_USUARIO, id );
export const getIdUsuario = () => localStorage.getItem( ID_USUARIO );

export const setNomeUsuario = nome => localStorage.setItem( NOME_USUARIO, nome );
export const getNomeUsuario = () => localStorage.getItem( NOME_USUARIO );

//export const setTipoUsuario = tipo => localStorage.setItem( USER_TYPE, tipo );
//export const getTipoUsuario = () => localStorage.getItem( USER_TYPE );

//export const setSourceArea = source => localStorage.setItem( USER_SOURCE, source );
//export const getSourceArea = () => localStorage.getItem( USER_SOURCE );

export const setTipoUsuario = async (tipo) =>{
    const response = await api.post('/api/encrypt',{ text:tipo });
    localStorage.setItem( USER_TYPE, response.data );
}
export const getTipoUsuario = async () =>{
    const tipo = localStorage.getItem( USER_TYPE );
    let resp = '';

    if (tipo)
        resp = await api.post('/api/decrypt',{ text:tipo });

    return resp.data;
}




export const setSourceArea = async (source) =>{
    const response = await api.post('/api/encrypt',{ text:source });
    localStorage.setItem( USER_SOURCE, response.data );
}
export const getSourceArea = async () =>{
    const source = localStorage.getItem( USER_SOURCE );
    let resp = '';

    if (source)
        resp = await api.post('/api/decrypt',{ text:source });

    return resp.data;
}




