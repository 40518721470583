import { useRoutes } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import OpenGymRoute from './OpenGymRoute';
import GymRoute from './GymRoute';
import GymBasicRoute from './GymBasicRoute';
//import UserAppRoute from './UserAppRoute';


// ==============================|| ROUTING RENDER ||============================== //
// TIPOS DE LOGIN / ACESSO:
// AuthenticationRoutes - Rotas de Autenticação - sem Header
// login/source:1 - OpenGymRoute - Somente usuários Admin OpenGym
// login/source:2 - GymRoute - Somente Administradores/Funcionários de Academias
// login/source:3 - privateUserApp - Somente Alunos ( Ainda não Criado )
//return useRoutes([AuthenticationRoutes, OpenGymRoute, GymRoute]);

export default function ThemeRoutes() {
    return useRoutes([AuthenticationRoutes, OpenGymRoute, GymRoute, GymBasicRoute]);
    //    return useRoutes([AuthenticationRoutes, OpenGymRoute, GymRoute, UserAppRoute]);
}