import { lazy } from 'react';

// project imports
import MainLayout from '../components/menu-gym-basic';
import Loadable from '../components/Loadable';
import { ProtectedRoute } from "./ProtectedRoute";

//import { mainListItems_o } from './opengym/list-menu-admin';
//import { mainListItems_g } from './gym/list-menu-admin';
//import { mainListItems_u } from './user/list-menu-admin';

const PanelCheckin = Loadable(lazy(() => import('../pages/admin/gym/checkinPanel')));

// ==============================|| MAIN ROUTING ||============================== //
// Validação do Token
// source = 1 - Area OpenGym
// source = 2 - Area Academia Adm
// source = 3 - Area Aluno

const GymBasicRoute = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/gym/painel',
            element: <ProtectedRoute source='2'><PanelCheckin /></ProtectedRoute>
        },
//        {
//            path: '/account2/:idUsuario',
//            element: <ProtectedRoute source='2'><Account /></ProtectedRoute>
//        },
    ]
};

export default GymBasicRoute;
