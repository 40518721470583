import FitnessCenter from '@material-ui/icons/FitnessCenter';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import { getIdUsuario } from '../../services/auth';

const userId = getIdUsuario();

/* EX SUBMENU

{
    name: "cadastros",
    label: "Cadastros",
    Icon: AssignmentIcon,
    items: [
        { name: "usuarios", label: "Usuários", Icon: PeopleIcon, link: '/gym/usuarios/'+userId, nodeExpand: 'cadastros' },
        { name: "academias", label: "Academias", Icon: StorefrontOutlinedIcon, link: '/gym/academia/'+userId, nodeExpand: 'cadastros' }
    ]
},

*/

export const mainListItems_g = [
    { name: "dashboard", label: "Dashboard", Icon: DashboardOutlinedIcon, link: '/gym/dashboard', nodeExpand: '' },
    { name: "checkins", label: "Check-ins", Icon: FitnessCenter, link: '/gym/checkins/'+userId, nodeExpand: '' },
    { name: "academias", label: "Academias da conta", Icon: StorefrontOutlinedIcon, link: '/gym/academia/'+userId, nodeExpand: '' },
    { name: "preco", label: "Preço dinâmico", Icon: MonetizationOnOutlinedIcon, link: '/gym/precodinamico/'+userId, nodeExpand: '' },
    { name: "financeiro", label: "Financeiro", Icon: AccountBalanceOutlinedIcon, link: '/gym/financeiro/'+userId, nodeExpand: '' },
    { name: "usuarios", label: "Usuários da conta", Icon: PeopleAltOutlinedIcon, link: '/gym/usuarios/'+userId, nodeExpand: '' },
    { name: "account", label: "Meu perfil", Icon: AccountCircleOutlinedIcon, link: '/account2/' +userId, nodeExpand: '' },    
];

