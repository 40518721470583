import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import Loadable from '../components/Loadable';
import MinimalLayout from '../components/MinimalLayout';

// login option 3 routing
// const Home = Loadable(lazy(() => import('../pages/client/home')));

const Partners = Loadable(lazy(() => import('../pages/client/partners')));
const PartnersDetail = Loadable(lazy(() => import('../pages/client/partners/partners.detail')));

const LoginOpenGym = Loadable(lazy(() => import('../pages/admin/opengym/login')));
const LoginGym = Loadable(lazy(() => import('../pages/admin/gym/login')));

const ForgotPasswordOpenGym = Loadable(lazy(() => import('../pages/admin/opengym/login/forgotpassword')));
const ForgotPasswordGym = Loadable(lazy(() => import('../pages/admin/gym/login/forgotpassword')));

//const ResetPassword = Loadable(lazy(() => import('../pages/admin/shared/login/resetpassword')));
//const ResetPasswordOpenGym = Loadable(lazy(() => import('../pages/admin/opengym/login/resetpassword')));
//const ResetPasswordGym = Loadable(lazy(() => import('../pages/admin/gym/login/resetpassword')));

const NotFound = Loadable(lazy(() => import('../pages/client/notfound')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
// <Redirect exact from="/" to="searchDashboard" />

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <Navigate to='/area-parceiro' replace />,
        },
        {
            path: '/partners',
            element: <Partners />
        },
        {
            path: '/partners/:idAcademia',
            element: <PartnersDetail />
        },
        {
            path: '/chanel-e-lana',
            element: <LoginOpenGym />
        },
        {
            path: '/login',
            element: <LoginGym />
        },
        {
            path: '/area-parceiro',
            element: <LoginGym />
        },
        {
            path: '/forgotpassword-chanel-e-lana',
            element: <ForgotPasswordOpenGym />
        },
        {
            path: '/forgotpassword-area-parceiros',
            element: <ForgotPasswordGym />
        },
//        {
//            path: '/resetpassword/:source/:token',
//            element: <ResetPassword />
//        },
//        {
//            path: '/resetpassword-area-parceiros/:token',
//            element: <ResetPasswordGym />
//        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
        {
            path: '/404',
            element: <NotFound />
        }
    ]
};

export default AuthenticationRoutes;
